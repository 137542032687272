import React from "react";
import HomeForm from "./include/HomeFrom";  

function Home() {
  return (
    <div>
      <div
        data-elementor-type="wp-page"
        data-elementor-id={14}
        className="elementor elementor-14"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-7a1056f elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="7a1056f"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4329e7d"
              data-id="4329e7d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-d7101df elementor-widget elementor-widget-go-islider"
                  data-id="d7101df"
                  data-element_type="widget"
                  data-widget_type="go-islider.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-hero-1-area pt-90 pb-100 ">
                      <div className="kd-hero-1-bg-img ">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/h1-bg-1.png"
                          alt=""
                        />
                      </div>
                      <div className="container kd-container-1">
                        <div className="kd-hero-1-slider mb-50">
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/h1-il-5.webp"
                            alt=""
                            className="kd-hero-1-bg-il-1 txa-mm-elm"
                            data-value={3}
                          />
                          <img
                            decoding="async"
                            src="https://universitybureau.com/content/uploads/2024/05/h1-il-5.webp"
                            alt=""
                            className="kd-hero-1-bg-il-2 txa-mm-elm"
                            data-value={5}
                          />
                          <div className="swiper-container fix kd-hero-1-active">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                <div className="kd-hero-1-item">
                                  <div className="row align-items-center">
                                    {/* left-content */}
                                    <div className="col-lg-6">
                                      <div className="kd-hero-1-item-content">
                                        <h6 className="kd-subtitle-1 has-clr-2 elementor-kadu-sub">
                                          <img
                                            decoding="async"
                                            src="https://universitybureau.com/content/uploads/2024/05/star-2.webp"
                                            alt=""
                                          />
                                          Your Future Starts Here
                                        </h6>
                                        <h1 className="elementor-gt-heading title kd-heading-1 kd-font-900">
                                          Navigate Your Academic Journey
                                          <br />
                                          Expert Guidance for Every Student
                                        </h1>
                                        <p className="kd-para-1 disc has-clr-white elementor-gt-desc">
                                          Expert Support for Academic
                                          Challenges, Personal Growth, and Life
                                          Skills
                                        </p>
                                        <div className="btn-wrap">
                                          <a
                                            href="https://portal.universitybureau.com/register"
                                            target="_self"
                                            rel=""
                                            aria-label="name"
                                            className="kd-pr-btn-1 text-uppercase has-black"
                                          >
                                            <span
                                              className="text"
                                              data-back="find out more"
                                              data-front="find out more"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    {/* right-content */}
                                    <div className="col-lg-6">
                                      <div className="kd-hero-1-item-img">
                                        <div className="kd-hero-1-item-img-1 fix">
                                          <img
                                            decoding="async"
                                            src="https://universitybureau.com/content/uploads/2024/05/h1-img-1.webp"
                                            alt=""
                                          />
                                        </div>
                                        <div className="kd-hero-1-item-img-2 fix img-cover">
                                          <img
                                            decoding="async"
                                            src="https://universitybureau.com/content/uploads/2024/05/h1-img-2.webp"
                                            alt=""
                                          />
                                        </div>
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-1"
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-1.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-2"
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-2.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-3 txa-mm-elm"
                                          data-value={5}
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-3.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-4 txa-mm-elm"
                                          data-value={8}
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-4.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-5 txa-mm-elm"
                                          data-value={6}
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-4.webp"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="kd-hero-1-item">
                                  <div className="row align-items-center">
                                    {/* left-content */}
                                    <div className="col-lg-6">
                                      <div className="kd-hero-1-item-content">
                                        <h6 className="kd-subtitle-1 has-clr-2 elementor-kadu-sub">
                                          <img
                                            decoding="async"
                                            src="https://universitybureau.com/content/uploads/2024/05/star-2.webp"
                                            alt=""
                                          />
                                          Guiding You Through Challenges
                                        </h6>
                                        <h1 className="elementor-gt-heading title kd-heading-1 kd-font-900">
                                          Unlock Your Potential: Personalized
                                          Counseling for Student Success
                                        </h1>
                                        <p className="kd-para-1 disc has-clr-white elementor-gt-desc">
                                          Where Compassion Meets Expertise for
                                          Student Empowerment
                                        </p>
                                        <div className="btn-wrap">
                                          <a
                                            href="https://portal.universitybureau.com/register"
                                            target="_self"
                                            rel=""
                                            aria-label="name"
                                            className="kd-pr-btn-1 text-uppercase has-black"
                                          >
                                            <span
                                              className="text"
                                              data-back="find out more"
                                              data-front="find out more"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    {/* right-content */}
                                    <div className="col-lg-6">
                                      <div className="kd-hero-1-item-img">
                                        <div className="kd-hero-1-item-img-1 fix">
                                          <img
                                            decoding="async"
                                            src="https://universitybureau.com/content/uploads/2024/05/h1-img-1.webp"
                                            alt=""
                                          />
                                        </div>
                                        <div className="kd-hero-1-item-img-2 fix img-cover">
                                          <img
                                            decoding="async"
                                            src="https://universitybureau.com/content/uploads/2024/05/h1-img-2.webp"
                                            alt=""
                                          />
                                        </div>
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-1"
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-1.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-2"
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-2.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-3 txa-mm-elm"
                                          data-value={5}
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-3.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-4 txa-mm-elm"
                                          data-value={8}
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-4.webp"
                                          alt=""
                                        />
                                        <img
                                          decoding="async"
                                          className="kd-hero-1-item-img-il-5 txa-mm-elm"
                                          data-value={6}
                                          src="https://universitybureau.com/content/uploads/2024/05/h1-il-4.webp"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="kd-hero-1-bottom d-flex justify-content-between align-items-center">
                          <div className="kd-hero-1-social d-flex align-items-center flex-wrap">
                            <a
                              href="https://www.facebook.com/universitybureau"
                              target="_self"
                              rel=""
                              aria-label="name"
                              className="link kd-heading-1 kd-font-600 has-clr-white"
                            >
                              facebook
                            </a>
                            <a
                              href="https://x.com/universitybure1"
                              target="_self"
                              rel="nofollow"
                              aria-label="name"
                              className="link kd-heading-1 kd-font-600 has-clr-white"
                            >
                              twitter
                            </a>
                            <a
                              href="https://www.instagram.com/universitybureau/"
                              target="_self"
                              rel=""
                              aria-label="name"
                              className="link kd-heading-1 kd-font-600 has-clr-white"
                            >
                              Instagram
                            </a>
                          </div>
                          <div className="kd-hero-1-slider-btn d-flex align-items-center">
                            <div className="slider-btn kd-heading-1 kd-font-600 has-clr-white kd_hero_1_prev">
                              prev
                            </div>
                            <div className="slider-btn kd-heading-1 kd-font-600 has-clr-white kd_hero_1_next">
                              next
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="bc7b349"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-195e397"
              data-id="195e397"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-cfed025 elementor-widget elementor-widget-go-course-tab"
                  data-id="cfed025"
                  data-element_type="widget"
                  data-widget_type="go-course-tab.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-course-1-area pt-130 pb-60 fix">
                      <div className="container kd-container-1">
                        {/* sectiont-title */}
                        <div className="kd-course-1-scn-title">
                          <div className="text-center w-100">
                            <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                              Unlock Global Opportunities{" "}
                            </h6>
                            <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                              Your Pathway to Excellence and <br />
                              Study Abroad Navigator
                            </h1>
                          </div>
                        </div>
                        {/* tabs-content */}
                        <div
                          className="tab-content kd-course-1-tabs-content-wrap"
                          id="myTabContent"
                        >
                          {/* sinlge-content-wrap */}
                          <div
                            className="tab-pane fade animated fadeInUp show active kd-course-1-tabs-content"
                            id="home0"
                            role="tabpanel"
                            aria-labelledby="home-tab0"
                          >
                            {/* slider */}
                            <div className="swiper-container fix kd-course-1-active">
                              <div className="swiper-wrapper">
                                <div className="swiper-slide swiper-slide-custom-1">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={352}
                                        height={235}
                                        src="https://universitybureau.com/images/can-2.png"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                        sizes="(max-width: 352px) 100vw, 352px"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900 text-center">
                                        <a href="/studydestinations/canada" aria-label="name">
                                          Canada
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide swiper-slide-custom-1">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={352}
                                        height={235}
                                        src="https://universitybureau.com/images/uk-1.png"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                        sizes="(max-width: 352px) 100vw, 352px"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900 text-center">
                                        <a href="/studydestinations/uk" aria-label="name">
                                          United Kingdom
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide swiper-slide-custom-1">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={352}
                                        height={235}
                                        src="https://universitybureau.com/images/usa-1.png"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                        sizes="(max-width: 352px) 100vw, 352px"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900 text-center">
                                        <a href="/studydestinations/usa" aria-label="name">
                                          USA
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide swiper-slide-custom-1">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={352}
                                        height={235}
                                        src="https://universitybureau.com/images/aus-1.png"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                        sizes="(max-width: 352px) 100vw, 352px"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900 text-center">
                                        <a href="/studydestinations/australia" aria-label="name">
                                          Australia
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide swiper-slide-custom-1">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={352}
                                        height={235}
                                        src="https://universitybureau.com/images/eur-2.png"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                        sizes="(max-width: 352px) 100vw, 352px"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900 text-center">
                                        <a href="/studydestinations/europe" aria-label="name">
                                          Europe
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* slider-btn */}
                            <div className="kd-course-1-tabs-slider-btn">
                              <div className="kd_course_1_slider_prev">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                </div>
                              </div>
                              <div className="kd_course_1_slider_next">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center has-next">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-943e56e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="943e56e"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-23f6d50"
              data-id="23f6d50"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-6cdc10e elementor-widget elementor-widget-go-s-heading"
                  data-id="6cdc10e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        Premium Courses
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Popular Category
                      </h1>
                      <div
                        className="elementor-gt-desc kd-para-1 disc wow"
                        data-splitting=""
                      >
                        Select fom the World's most popular Courses
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c8c439b elementor-widget elementor-widget-ft-btn"
                  data-id="c8c439b"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="https://portal.universitybureau.com/login"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="find out more"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-878c6de"
              data-id="878c6de"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-4923d22 elementor-widget elementor-widget-go-category"
                  data-id="4923d22"
                  data-element_type="widget"
                  data-widget_type="go-category.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-pop-cat-1-slider">
                      <div className="swiper-container fix kd-pop-cat-1-active">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="kd-pop-cat-1-item text-center">
                              <img
                                decoding="async"
                                src="https://universitybureau.com/content/uploads/2024/06/pc-1-shape-1.webp"
                                alt=""
                                className="shape"
                              />
                              <div className="icon d-flex align-items-center justify-content-center">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/pc-1-icon-1.webp"
                                  alt=""
                                />
                              </div>
                              <h4 className="title kd-heading-1 has-clr-white kd-font-700 has-bg-1">
                                <a
                                  href="https://themexriver.com/wp/kadu/course-category/digital-marketing/?tutor-course-filter-category=2"
                                  aria-label="name"
                                >
                                  {" "}
                                  Digital Marketing{" "}
                                </a>
                              </h4>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="kd-pop-cat-1-item text-center">
                              <img
                                decoding="async"
                                src="https://universitybureau.com/content/uploads/2024/06/pc-1-shape-1.webp"
                                alt=""
                                className="shape"
                              />
                              <div className="icon d-flex align-items-center justify-content-center">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/pc-1-icon-2.webp"
                                  alt=""
                                />
                              </div>
                              <h4 className="title kd-heading-1 has-clr-white kd-font-700 has-bg-1">
                                <a
                                  href="https://themexriver.com/wp/kadu/course-category/it-and-software/?tutor-course-filter-category=5"
                                  aria-label="name"
                                >
                                  {" "}
                                  it and software{" "}
                                </a>
                              </h4>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="kd-pop-cat-1-item text-center">
                              <img
                                decoding="async"
                                src="https://universitybureau.com/content/uploads/2024/06/pc-1-shape-1.webp"
                                alt=""
                                className="shape"
                              />
                              <div className="icon d-flex align-items-center justify-content-center">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/pc-1-icon-3.webp"
                                  alt=""
                                />
                              </div>
                              <h4 className="title kd-heading-1 has-clr-white kd-font-700 has-bg-1">
                                <a
                                  href="https://themexriver.com/wp/kadu/course-category/art-humatities/?tutor-course-filter-category=4"
                                  aria-label="name"
                                >
                                  {" "}
                                  art &amp; humatities{" "}
                                </a>
                              </h4>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="kd-pop-cat-1-item text-center">
                              <img
                                decoding="async"
                                src="https://universitybureau.com/content/uploads/2024/06/pc-1-shape-1.webp"
                                alt=""
                                className="shape"
                              />
                              <div className="icon d-flex align-items-center justify-content-center">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/pc-1-icon-4.webp"
                                  alt=""
                                />
                              </div>
                              <h4 className="title kd-heading-1 has-clr-white kd-font-700 has-bg-1">
                                <a
                                  href="https://themexriver.com/wp/kadu/course-category/web-development/?tutor-course-filter-category=3"
                                  aria-label="name"
                                >
                                  {" "}
                                  web development{" "}
                                </a>
                              </h4>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="kd-pop-cat-1-item text-center">
                              <img
                                decoding="async"
                                src="https://universitybureau.com/content/uploads/2024/06/pc-1-shape-1.webp"
                                alt=""
                                className="shape"
                              />
                              <div className="icon d-flex align-items-center justify-content-center">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/pc-1-icon-1.webp"
                                  alt=""
                                />
                              </div>
                              <h4 className="title kd-heading-1 has-clr-white kd-font-700 has-bg-1">
                                <a
                                  href="https://themexriver.com/wp/kadu/course-category/art-humatities/?tutor-course-filter-category=4"
                                  aria-label="name"
                                >
                                  {" "}
                                  art &amp; humatities{" "}
                                </a>
                              </h4>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="kd-pop-cat-1-item text-center">
                              <img
                                decoding="async"
                                src="https://universitybureau.com/content/uploads/2024/06/pc-1-shape-1.webp"
                                alt=""
                                className="shape"
                              />
                              <div className="icon d-flex align-items-center justify-content-center">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/pc-1-icon-2.webp"
                                  alt=""
                                />
                              </div>
                              <h4 className="title kd-heading-1 has-clr-white kd-font-700 has-bg-1">
                                <a
                                  href="https://themexriver.com/wp/kadu/course-category/web-development/?tutor-course-filter-category=3"
                                  aria-label="name"
                                >
                                  {" "}
                                  web development{" "}
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="kd-pop-cat-1-slider-btn">
                        <div className="kd_pop_cat_1_prev">
                          <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center txaa-fade-right">
                            <span className="icon-1">
                              <i className="fa-solid fa-arrow-left" />
                            </span>
                            <span className="icon-2">
                              <i className="fa-solid fa-arrow-left" />
                            </span>
                          </div>
                        </div>
                        <div className="kd_pop_cat_1_next ">
                          <div className="kd-slider-btn-1 has-next d-flex align-items-center justify-content-center txaa-fade-left">
                            <span className="icon-1">
                              <i className="fa-solid fa-arrow-right" />
                            </span>
                            <span className="icon-2">
                              <i className="fa-solid fa-arrow-right" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-3ea5f3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="3ea5f3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-38a86bb"
              data-id="38a86bb"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-f336617 elementor-widget elementor-widget-go-img--bx"
                  data-id="f336617"
                  data-element_type="widget"
                  data-widget_type="go-img--bx.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-about-1-left">
                      <div className="kd-about-1-img-1 fix  kd-img-ani-1">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/kd-a1-img-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-about-1-img-2 fix img-cover kd-img-ani-1">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/kd-a1-img-2.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-about-1-expart "></div>
                      <div className="kd-about-1-img-certified txaa-roteted-1">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/kd-a1-certified.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5b3e5cc"
              data-id="5b3e5cc"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-adf247e elementor-widget elementor-widget-go-s-heading"
                  data-id="adf247e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        Guaranteed &amp; certified
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Unlock Global Opportunities with University Bureau
                      </h1>
                      <div
                        className="elementor-gt-desc kd-para-1 disc wow"
                        data-splitting=""
                      ></div>
                    </div>
                  </div>
                </div>
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-408f033 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="408f033"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6cc76fd"
                      data-id="6cc76fd"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-f3aa527 elementor-widget elementor-widget-go-img--bx"
                          data-id="f3aa527"
                          data-element_type="widget"
                          data-widget_type="go-img--bx.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-about-1-feature-img txaa-fade-right">
                              <div className="single-img fix img-cover ">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/kd-a1-img-4.webp"
                                  alt=""
                                />
                              </div>
                              <div className="single-img fix img-cover">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/kd-a1-img-5.webp"
                                  alt=""
                                />
                              </div>
                              <div className="single-img fix img-cover">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/kd-a1-img-6.webp"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-92e5a65 elementor-widget elementor-widget-go-list-item"
                          data-id="92e5a65"
                          data-element_type="widget"
                          data-widget_type="go-list-item.default"
                        >
                          <div className="elementor-widget-container">
                            <ul className="kd-list-1 kd-heading-1 kd-font-800 ">
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Top Universities
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  5,000+ Universities
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  10,000+ Courses 
                                </span>
                              </li>
                              <li>
                                <i className="fa-solid fa-circle-check" />
                                <span className="wow" data-splitting="">
                                  Very Easy
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-f5fa00b elementor-widget elementor-widget-ft-btn"
                          data-id="f5fa00b"
                          data-element_type="widget"
                          data-widget_type="ft-btn.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="prthalign">
                              <a
                                href="https://portal.universitybureau.com/register"
                                rel="nofollow"
                                aria-label="name"
                                className="kd-pr-btn-1 text-uppercase has-pr-clr"
                              >
                                <span
                                  className="text"
                                  data-back="find out more"
                                  data-front="find out more"
                                />
                                <span className="icon">
                                  <i className="fa-solid fa-arrow-right" />
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-c60d7d0 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="c60d7d0"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-31505e1"
              data-id="31505e1"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-c5fed59 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="c5fed59"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac32b87"
                      data-id="ac32b87"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-e36021c elementor-widget elementor-widget-go-img--bx"
                          data-id="e36021c"
                          data-element_type="widget"
                          data-widget_type="go-img--bx.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-choose-us-1-left">
                              <div className="kd-choose-us-1-img fix img-cover kd-img-ani-1">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/c-us-1-img-1.webp"
                                  alt=""
                                />
                              </div>
                              <div
                                className="kd-choose-us-1-img-il-1 txa-mm-elm"
                                data-value={5}
                              >
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/c-us-1-il-1.webp"
                                  alt=""
                                />
                              </div>
                              <div className="kd-choose-us-1-bg-shape-1 txaa-roteted-1">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/06/c-us-1-bg-shape-1.webp"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2cf8ac9"
                      data-id="2cf8ac9"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-5a0dc78 elementor-widget elementor-widget-go-s-heading"
                          data-id="5a0dc78"
                          data-element_type="widget"
                          data-widget_type="go-s-heading.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="prthalign">
                              <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                                  alt=""
                                />
                                get to know us
                              </h6>
                              <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                                Don’t Know How To Apply
                              </h1>
                              <div
                                className="elementor-gt-desc kd-para-1 disc wow"
                                data-splitting=""
                              >
                                <p>
                                  With Our Easy To Apply Application Process You
                                  can easily Appy to World's Top Universities.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-bb693ab elementor-widget elementor-widget-go-features"
                          data-id="bb693ab"
                          data-element_type="widget"
                          data-widget_type="go-features.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-choose-us-1-right">
                              <div className="inner-div">
                                <div className="kd-choose-us-1-il-1">
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/c-us-1-il-3.webp"
                                    alt="List"
                                  />
                                </div>
                                <div className="kd-choose-us-1-feature-wrap">
                                  <div className="kd-choose-us-1-feature">
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/c-us-1-icon-1.webp"
                                        alt="List"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5
                                        className="title kd-heading-1 has-clr-white has-clr-white kd-font-900  wow"
                                        data-splitting=""
                                      >
                                        Browse University Bureau
                                      </h5>
                                      <p className="disc kd-para-1">
                                        Find The Top and Best Universities in
                                        the world.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="kd-choose-us-1-feature">
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="https://universitybureau.com/content/uploads/2024/06/c-us-1-icon-2.webp"
                                        alt="List"
                                      />
                                    </div>
                                    <div className="content">
                                      <h5
                                        className="title kd-heading-1 has-clr-white has-clr-white kd-font-900  wow"
                                        data-splitting=""
                                      >
                                        Talk to Our Student Counselor
                                      </h5>
                                      <p className="disc kd-para-1">
                                        Our Team will Help you to Grab That
                                        Opportunity!
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="elementor-element elementor-element-fc525fb elementor-widget elementor-widget-go-animate-bg"
                  data-id="fc525fb"
                  data-element_type="widget"
                  data-widget_type="go-animate-bg.default"
                >
                  <div className="elementor-widget-container">
                    <span className="kd-choose-us-1-bg-shape d-block txaa-slide-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        services
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Get The World's Best Services
                      </h1>
                      <div
                        className="elementor-gt-desc kd-para-1 disc wow"
                        data-splitting=""
                      >
                        <p>
                          Unlock your academic potential, explore the endless
                          possibilities, and secure your future with the
                          University Bureau.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e4cf9e0 elementor-widget elementor-widget-ft-btn"
                  data-id="e4cf9e0"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="/contact"
                        target="_blank"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="find out more"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a
                                  href="https://portal.universitybureau.com/register"
                                  aria-label="name"
                                >
                                  For Students
                                </a>
                              </h4>
                              <p className="disc kd-para-1">
                                Best Services, Easy Application Process, Top
                                Universities For Students
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ffaa4bd"
                      data-id="ffaa4bd"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-7de39a5 elementor-widget elementor-widget-go-info-box"
                          data-id="7de39a5"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-2.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a
                                  href="https://portal.universitybureau.com/register"
                                  aria-label="name"
                                >
                                  For Universities
                                </a>
                              </h4>
                              <p className="disc kd-para-1">
                                Students Applications, Increase in Reach,
                                University Promotion
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-5b9f94a elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="5b9f94a"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1c6eade"
              data-id="1c6eade"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-3981c62 elementor-widget elementor-widget-go-s-heading"
                  data-id="3981c62"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-3.webp"
                          alt=""
                        />
                        testimonial
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        What Students Have To Say
                      </h1>
                      <div
                        className="elementor-gt-desc kd-para-1 disc wow"
                        data-splitting=""
                      >
                        <p>
                          With the aim of Empowering Dreams, Forging Success!
                          <br />
                          Look what Students, Universities, and Recruiters say
                          about us!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-3f49461 elementor-widget elementor-widget-ft-btn"
                  data-id="3f49461"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="/contact"
                        target="_blank"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="find out more"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-1e15263"
              data-id="1e15263"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-a0a0689 elementor-widget elementor-widget-go-testimonial-id"
                  data-id="a0a0689"
                  data-element_type="widget"
                  data-widget_type="go-testimonial-id.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-testimonial-1-slider txaa-fade-right">
                      <div className="swiper-container fix kd-testimonial-1-active">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="kd-testimonial-1-item">
                              <div className="main-img fix img-cover">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/t1-img-1.webp"
                                  alt=""
                                />
                              </div>
                              <div className="item-content">
                                <div className="icon">
                                  <i className="fa-solid fa-quote-right" />
                                </div>
                                <div className="kd-ratting-1 d-inline-flex align-items-center flex-wrap">
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                </div>
                                <h5 className="item-comment kd-heading-1">
                                  University Bureau's expertise transcends
                                  borders. With their help, I got into
                                  University College Birmingham and experienced
                                  the best UK education.
                                </h5>
                                <div className="kd-testimonial-1-item-author d-flex align-items-center">
                                  <div className="author-img fix img-cover">
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/images/testimonial/is-3.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="author-content">
                                    <h4 className="name kd-heading-1 kd-font-900">
                                      Rahul Khanna
                                    </h4>
                                    <p className="bio kd-para-1">
                                      Behavioral Science
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="kd-testimonial-1-item">
                              <div className="main-img fix img-cover">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/t1-img-2.webp"
                                  alt=""
                                />
                              </div>
                              <div className="item-content">
                                <div className="icon">
                                  <i className="fa-solid fa-quote-right" />
                                </div>
                                <div className="kd-ratting-1 d-inline-flex align-items-center flex-wrap">
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                  <i className="fa-solid fa-star" />
                                </div>
                                <h5 className="item-comment kd-heading-1">
                                  University Bureau's assistance in getting me
                                  accepted to Acadia University was an essential
                                  step to my success as an international
                                  student.!
                                </h5>
                                <div className="kd-testimonial-1-item-author d-flex align-items-center">
                                  <div className="author-img fix img-cover">
                                    <img
                                      decoding="async"
                                      src="https://universitybureau.com/images/testimonial/is-2.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="author-content">
                                    <h4 className="name kd-heading-1 kd-font-900">
                                      Priya Desai
                                    </h4>
                                    <p className="bio kd-para-1">
                                      Hotel Management
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* slider-btn */}
                      <div className="kd-testimonial-1-slider-btn">
                        <div className="kd_testimonial_1_slider_prev">
                          <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center txaa-fade-right">
                            <span className="icon-1">
                              <i className="fa-solid fa-arrow-left" />
                            </span>
                            <span className="icon-2">
                              <i className="fa-solid fa-arrow-left" />
                            </span>
                          </div>
                        </div>
                        <div className="kd_testimonial_1_slider_next">
                          <div className="kd-slider-btn-1 has-next d-flex align-items-center justify-content-center txaa-fade-left">
                            <span className="icon-1">
                              <i className="fa-solid fa-arrow-right" />
                            </span>
                            <span className="icon-2">
                              <i className="fa-solid fa-arrow-right" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-3961af3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="3961af3"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d657859"
              data-id="d657859"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-56d9bbf elementor-widget elementor-widget-go-s-cta"
                  data-id="56d9bbf"
                  data-element_type="widget"
                  data-widget_type="go-s-cta.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-cta-1-area txaa-scale-up">
                      <div className="container kd-container-1">
                        <div className="kd-cta-1-wrap ">
                          <div className="left">
                            <h3
                              className="title kd-heading-1 kd-font-800 has-clr-white wow"
                              data-splitting=""
                            >
                              Thousands of Courses Authored By Industries
                            </h3>
                            <div className="kd-cta-1-action">
                              <div className="icon d-flex align-items-center justify-content-center">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-phone"
                                />
                              </div>
                              <div className="action-content">
                                <h6 className="title kd-heading-1 kd-font-800 has-clr-white">
                                  get in touch
                                </h6>
                                <a
                                  href="mailto:info@universitybureau.com"
                                  aria-label="name"
                                  className="action-link kd-heading-1 has-clr-white kd-font-900"
                                >
                                  info@universitybureau.com
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="meddle">
                            <div className="kd-counter-1-item-wrap">
                              <div className="kd-counter-1-item">
                                <h4 className="item-number kd-heading-1 has-clr-white kd-font-800">
                                  <span className="counter">45</span>k
                                </h4>
                                <p className="disc kd-heading-1 has-clr-white kd-font-700">
                                  active students
                                </p>
                              </div>
                              <div className="kd-counter-1-item">
                                <h4 className="item-number kd-heading-1 has-clr-white kd-font-800">
                                  <span className="counter">120</span>+
                                </h4>
                                <p className="disc kd-heading-1 has-clr-white kd-font-700">
                                  Top Universities
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="main-img fix img-cover">
                            <img
                              decoding="async"
                              src="https://universitybureau.com/content/uploads/2024/05/cta-1-img-1.webp"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-df383b9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="df383b9"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aab349a"
              data-id="aab349a"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-5359071 elementor-widget elementor-widget-go-s-heading"
                  data-id={5359071}
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-3.webp"
                          alt=""
                        />
                        Get In Touch
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Connecting Dreams Globally, Shaping Futuress
                      </h1>
                    </div>
                  </div>
                  <div className="elementor-widget-container">
                    <div
                      className="elementor-element elementor-element-1996b2f elementor-widget elementor-widget-shortcode"
                      data-id="1996b2f"
                      data-element_type="widget"
                      style={{ padding: "2rem 0" }}
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-shortcode">
                          <div
                            className="wpcf7 js"
                            id="wpcf7-f1935-p1924-o1"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response">
                              <p
                                role="status"
                                aria-live="polite"
                                aria-atomic="true"
                              />
                              <ul />
                            </div>
                            <HomeForm></HomeForm>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
