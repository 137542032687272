import React from "react";
import BG from "../../assets/imgs/banner-aus.png";

function Australia() {
  const bg = BG;

  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
          className="breadcrumb-area bg-default  pt-130 pb-130"
          style={{ marginTop: 166 }}
          data-background={bg}
        >
          <div className="container kd-container-1">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb-wrap"></div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Study In Australia
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-495e7eb elementor-widget elementor-widget-go-pricing-paln"
                  data-id="495e7eb"
                  data-element_type="widget"
                  data-widget_type="go-pricing-paln.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="kd-price-4-content-wrap d-block"
                      style={{ paddingBottom: 70 }}
                    >
                      <div className="kd-price-4-content-bg img-cover fix txaa-scalex-down">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/p3-item-bg-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-price-4-content">
                        <h5
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          Overview
                        </h5>
                        <p
                          className="disc kd-para-1 has-clr-white wow "
                          data-splitting=""
                        >
                        Australia is becoming a more and more popular choice for overseas students looking for a top-notch education to study in Australia. With its top-notch universities, state-of-the-art research facilities, and luxurious lifestyle, Australia provides international students with an unforgettable and enriching experience. The graduates from Australian institutions are equipped with qualifications that hold global value. This enhances their competitiveness in the international job market and positions them as sought-after professionals in their respective fields.
                        </p>
                      </div>
                      <div className="kd-price-4-content-list">
                        <h4
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          benefits of Australia
                        </h4>
                        <ul className="kd-list-1 kd-heading-1 kd-font-800">
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Education System
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Courses and Programs
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Renowned Universities
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Entry Requirements
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Visa Requirements
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Post-Study Options
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Why Choose Australia for Your <br />
                        Academic Pursuits
                      </h1>
                      <p>
                        Australia's education system is recognized globally, ensuring graduates possess internationally valued qualifications. Australia's appeal as an academic destination lies in its renowned institutions, diverse program offerings, global recognition, commitment to quality education, and a multicultural environment that collectively contributes to a rewarding educational experience for international students. Reasons to choose Australia for your academic pursuits are:
                      </p>
                    </div>
                  </div>
                  <div className="elementor-widget-container">
                    <div
                      id="categories-2"
                      className="widget_categories sidebar-widget"
                    >
                      <ul>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Top Education Universities:</b>
                              <br />
                              Australia stands out as a preferred destination for academic pursuits, boasting internationally acclaimed institutions like Elite Education Institute, Western Sydney Technology College, High Skilled Education & Training Australia Pvt. Ltd., Federation University, and Western Sydney University.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Diverse Range of Programs:</b>
                              <br />
                              These institutions offer a comprehensive selection of academic programs, encompassing diplomas, bachelor's degrees, postgraduate studies, and doctorate programs. This diversity allows students to tailor their education to specific career aspirations.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Global Recognition:</b>
                              <br />
                              Australia's education system enjoys global recognition, ensuring that graduates possess highly esteemed qualifications. This recognition enhances the employability and opportunities for students on the global stage.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Commitment to Quality Education:</b>
                              <br />
                              The country's commitment to delivering high-quality education is evident in its institutions, emphasizing excellence in teaching and fostering an environment conducive to learning.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Innovative Research Environment:</b>
                              <br />
                              Australia is a hub for innovative research, providing students with the opportunity to engage in cutting-edge projects and contribute to advancements in their respective fields.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Multicultural Environment:</b>
                              <br />
                              The multicultural environment in Australia enhances the overall educational experience for international students. Exposure to diverse cultures and perspectives enriches the learning environment, preparing students for a globalized workforce.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Broad Spectrum of Choices:</b>
                              <br />
                              Programs such as Information Technology, Business, Mining, Arts, Biomedical Science, Education, and Engineering, ensure that students have a plethora of choices to align their academic pursuits with their unique career goals.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Accessible Doctorate Programs:</b>
                              <br />
                              Programs such as Information Technology, Business, Mining, Arts, Biomedical Science, Education, and Engineering, ensure that students have a plethora of choices to align their academic pursuits with their unique career goals.
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-3163e91 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="3163e91"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
          }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-888d83f"
              data-id="888d83f"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-789f478 elementor-widget elementor-widget-go-pricing-tab"
                  data-id="789f478"
                  data-element_type="widget"
                  data-widget_type="go-pricing-tab.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-price-1-area">
                      <div className="container kd-conainer-1">
                        <div className="kd-price-1-row d-flex">
                          {/* left-side */}
                          <div
                            className="kd-price-1-left w-100"
                            style={{
                              padding: 50,
                              justifyContent: "space-between",
                              gap: 200,
                            }}
                          >
                            {/* tabs-btn */}
                            <ul
                              className="kd-price-1-tabs-btn"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab0"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home0"
                                  type="button"
                                  role="tab"
                                  aria-controls="home0"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Undergraduate{" "}
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab1"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home1"
                                  type="button"
                                  role="tab"
                                  aria-controls="home1"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Post Graduate
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab2"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home2"
                                  type="button"
                                  role="tab"
                                  aria-controls="home2"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Diploma
                                </button>
                              </li>
                            </ul>
                            {/* tabs-content */}
                            <div
                              className="tab-content kd-price-1-tabs-content"
                              id="myTabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="home0"
                                role="tabpanel"
                                aria-labelledby="home-tab0"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      The required minimum percentage is 60%
                                      <br/>
                                      TOEFL, PTE, or IELTS are required. 6.5 overall, each band scoring 5.5
                                      <br />
                                      <br />
                                      Undergraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Arts / Design
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Mass Communication & Media
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        IT & Computing
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Agriculture/Science
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home1"
                                role="tabpanel"
                                aria-labelledby="home-tab1"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      A bachelor's degree with  65%  in your chosen field of study approved by the Australian educational system.
                                                            <br/>
                                      IELTS overall score of 6.5, no band lower than 6.
                                      <br />
                                      <br />
                                      Postgraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Management and MBA
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Information Technology
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering/Architecture
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business/Law
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Psychology
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Health/Nursing
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home2"
                                role="tabpanel"
                                aria-labelledby="home-tab2"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      The required minimum percentage (60% for diploma programs and 65% for bachelor's degree).
                                      <br/>
                                      A minimum of 6.0 overall is needed in the IELTS and you will need a score of 5.5 for immigration and further education.
                                      <br />
                                      <br />
                                      Diploma Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Journalism
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Science/Psychology
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business Administration
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Hospitality
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Information Technology
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering/Architecture
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-88d3249 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="88d3249"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a06ae77"
              data-id="a06ae77"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-aeadd90 elementor-widget elementor-widget-go-img--bx"
                  data-id="aeadd90"
                  data-element_type="widget"
                  data-widget_type="go-img--bx.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-about-3-img-wrap txaa-slide-down-1">
                      <div className="kd-about-3-img img-cover fix kd-img-ani-1">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/a3-img-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-about-3-img-2 img-cover fix txaa-slide-down-1-item">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/a3-img-2.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4203749"
              data-id={4203749}
              data-element_type="column"
              style={{
                flexDirection: "column",
                padding: "20px 70px",
                paddingRight: 0,
              }}
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9ceb82e elementor-widget elementor-widget-go-s-heading"
                  data-id="9ceb82e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Application Procedure in Australia
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-66aff77 elementor-widget elementor-widget-go-list-item"
                  data-id="66aff77"
                  data-element_type="widget"
                  data-widget_type="go-list-item.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-800 "
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Explore and choose a course
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Select Universities
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Prepare Required Documents
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Language Proficiency Test
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Submit Application
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Receive Offer Letter
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Accept the Offer
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Post Offer Acceptance
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Prepare for the Departure
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-19ff2bc elementor-widget elementor-widget-ft-btn"
                  data-id="19ff2bc"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="/#"
                        target="_blank"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="Apply Now"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        Required Documents
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Student Visa Requirements for Australia
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-500 "
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                      }}
                    >
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Enrollment in a Registered Course:</b>
                          <br />
                          For a full-time course listed on the Commonwealth Register of Institutions and Courses for Overseas Students, applicants must have an offer of enrollment or a Confirmation of Enrollment (CoE) from an Australian educational institution that is registered. (CRICOS).
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Genuine Temporary Entrant Requirement:</b>
                          <br />
                          Applicants must provide proof that they want to spend time studying in Australia. This evaluation takes into account several variables, such as the applicant's immigration background, links to their native nation, and how the selected course relates to their professional objectives.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Financial Capacity:</b>
                          <br />
                          The ability to pay for living expenses, tuition, and other costs related to studying and living in Australia is a Requiredfor applicants. The length and location of the course will determine how much money is needed.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>English Language Proficiency:</b>
                          <br />
                          The applicants must provide proof of their passing scores on accredited English language exams, such as Cambridge English: Advanced (CAE), the International English Language Testing System (IELTS), the Test of English as a Foreign Language (TOEFL), or Pearson Test of English (PTE), to demonstrate their proficiency in the language.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Health Requirements:</b>
                          <br />Candidates must fulfill standards for character and health. Usually, this entails passing a medical exam to verify good health and obtaining a police clearance certificate or other comparable document to verify moral character.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Overseas Student Health Cover:</b>
                          <br />Applicants must acquire and maintain OSHC. When studying in Australia, OSHC covers hospital stays and medical costs.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Biometric Data and Health Insurance:</b>
                          <br />
                          The applicants might have to submit biometric information (photos and fingerprints) as part of the visa application procedure. Moreover, candidates might have to keep up their health insurance for the whole time they are in Australia.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Visa Application:</b>
                          <br />
                          Either through a registered migration agency or the Department of Home Affairs website, applicants must submit a completed online visa application. All necessary paperwork and information must be included in the application, and the application fee for the visa must be paid.
                        </p>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in Australia
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="/#" aria-label="name">
                                  Undergraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">
                                 $15,000 - $33,000
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="/#" aria-label="name">
                                  Postgraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">$20,000 - $37,000</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginBottom: 20,
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <table className="cost-table-design">
                                <tbody>
                                  <tr>
                                      <th><b>Expense Category</b></th>
                                      <th><b>Estimated Monthly Cost (AUD)</b></th>
                                  </tr>
                                  <tr>
                                      <td>Accommodation</td>
                                      <td>AUD 800 - AUD 1,500</td>
                                  </tr>
                                  <tr>
                                      <td>Groceries and Food</td>
                                      <td>AUD 200 - AUD 400</td>
                                  </tr>
                                  <tr>
                                      <td>Internet and phone</td>
                                      <td>AUD 60 - AUD 100</td>
                                  </tr>
                                  <tr>
                                      <td>Health insurance</td>
                                      <td>AUD 50 - AUD 100</td>
                                  </tr>
                                  <tr>
                                      <td>Miscellaneous</td>
                                      <td>AUD 50 - AUD 100</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                  style={{ height: "100%" }}
                >
                  <div
                    className="elementor-widget-container"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="prthalign custom-flex-box-2"
                      style={{ textAlign: "right", height: "100%" }}
                    >
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in Australia
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        FAQs
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Fequently Asked Questions
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tutor-mt-40 kd-course-details-content">
                      <div className="cd-video-accordion">
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header is-active">
                          1. What are the popular study destinations in Australia?
                          </h4>
                          <div className="tutor-accordion-item-body" style={{}}>
                            <div className="item-body">
                              <p className="font-c-20">
                              Australia is home to many well-known universities, but some of the most well-liked places to study are Sydney, Melbourne, Brisbane, Perth, and Adelaide.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          2. What is the education system like in Australia?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            The educational system in Australia is divided into three levels: primary, secondary, and tertiary (universities and vocational). Globally, the higher education system is well regarded.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          3. How do I apply for a student visa to Australia?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            You must apply to an Australian educational institution that is registered, secure a Confirmation of Enrolment (COE), and then apply online for a student visa. Fulfilling the prerequisites for character and health is also crucial.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          4. Can I work while studying in Australia?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            Yes, during semesters, international students with a valid student visa are typically permitted to work part-time; during breaks, they are permitted to work full-time.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          5. How long is the duration of study programs in Australia?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            The duration varies according to the level of study. For instance, master's programs might take one to two years, but undergraduate programs usually take three to four years.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Australia;
